<template>
  <div>

    <v-btn color="blue"
           dark
           depressed
           @click="open">
      <v-icon left>mdi-home-group</v-icon>
      {{ $t('message.assignment') }}
    </v-btn>

    <v-dialog v-model="dialog"
              max-width="1000"
              scrollable
              persistent>
      <v-card>

        <v-overlay :value="overlay"
                   absolute
                   :color="$vuetify.theme.dark ?'secondary':'white'">
          <v-progress-circular
              indeterminate
              size="60"
              color="primary"
          ></v-progress-circular>
        </v-overlay>

        <v-card-title>
          {{ $t('message.assignment') }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">
          <v-card class="card-shadow">

            <v-tabs v-model="tab"
                    icons-and-text
                    centered grow
                    background-color="blue"
                    color="white"
                    dark>

              <v-tab href="#tab-1" class="font-weight-bold">
                {{ $t('btn.filter') }}
                <v-icon>mdi-filter-outline</v-icon>
              </v-tab>

              <v-tab href="#tab-2"
                     class="font-weight-bold"
                     @click="getSalepointsByIds">
                {{ $t('message.salepoints') }}
                <v-icon>mdi-home-group</v-icon>
              </v-tab>

            </v-tabs>

            <v-tabs-items v-model="tab">

              <v-tab-item value="tab-1">
                <v-card flat>
                  <v-card-text>

                    <v-row justify="center">

                      <v-col cols="8">

                        <v-item-group multiple v-model="filter.databaseIds">

                          <v-row justify="center">
                            <v-col cols="6" v-for="(db,i) in $store.state.api.databases" :key="i">
                              <v-item v-slot="{ active, toggle }" :value="db.id">
                                <v-card outlined
                                        class="border-2"
                                        :class="active ? 'border--blue':''">
                                  <div @click="toggle" class="cursor-pointer">
                                    <v-card-title class="fs-15">
                                      <v-avatar rounded size="30" color="blue">
                                        <v-icon dark dense>mdi-database-outline</v-icon>
                                      </v-avatar>
                                      &nbsp;
                                      {{ db.name }}
                                      <v-spacer/>
                                      <v-scroll-y-transition>
                                        <v-icon v-if="active" color="blue">mdi-check-circle</v-icon>
                                      </v-scroll-y-transition>
                                    </v-card-title>
                                  </div>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>

                        </v-item-group>

                        <v-row class="my-5">
                          <v-col cols="12">
                            <v-autocomplete v-model="filter.cityIds"
                                            prepend-icon="mdi-filter"
                                            multiple chips deletable-chips small-chips dense clearable
                                            :items="$store.state.api.cities"
                                            item-value="id"
                                            item-text="name"
                                            :label="$t('field.city')"
                                            hide-details></v-autocomplete>
                          </v-col>
                          <v-col cols="12">
                            <v-autocomplete v-model="filter.regionIds"
                                            prepend-icon="mdi-filter"
                                            multiple chips deletable-chips small-chips dense clearable
                                            :items="$store.state.api.regions"
                                            item-value="id"
                                            item-text="name"
                                            :label="$t('field.region')"
                                            hide-details></v-autocomplete>
                          </v-col>

                          <v-col cols="12">
                            <v-autocomplete v-model="filter.typeIds"
                                            prepend-icon="mdi-filter"
                                            multiple chips deletable-chips small-chips dense clearable
                                            :items="$store.state.api.salepointTypes"
                                            item-value="id"
                                            item-text="name"
                                            :label="$t('field.type')"
                                            hide-details></v-autocomplete>
                          </v-col>

                          <v-col cols="12">
                            <v-autocomplete v-model="filter.routeIds"
                                            prepend-icon="mdi-filter"
                                            multiple chips deletable-chips small-chips dense clearable
                                            :items="$store.state.api.salepointRoutes"
                                            item-value="id"
                                            item-text="name"
                                            :label="$t('field.route')"
                                            hide-details></v-autocomplete>
                          </v-col>

                          <v-col cols="12">

                            <v-btn block
                                   color="primary"
                                   depressed
                                   large
                                   :disabled="!filter.databaseIds.length"
                                   @click="handleFilter">
                              <v-icon left>mdi-magnify</v-icon>
                              {{ $t('btn.search') }}
                            </v-btn>

                          </v-col>


                        </v-row>

                      </v-col>

                    </v-row>

                  </v-card-text>
                </v-card>
              </v-tab-item>


              <v-tab-item value="tab-2">
                <v-card flat>
                  <v-card-text>

                    <v-skeleton-loader v-if="isLoading" type="table"/>

                    <div v-else>
                      <v-simple-table v-if="salepoints.length">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th>{{ $t('field.image') }}</th>
                            <th>{{ $t('field.code') }}</th>
                            <th>{{ $t('field.name') }}</th>
                            <th>{{ $t('field.phone') }}</th>
                            <th>{{ $t('field.city') }}</th>
                            <th>{{ $t('field.region') }}</th>
                            <th>Compte OPUS</th>
                            <th>Compte Gifty</th>
                            <th>{{ $t('field.points') }}</th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item,i) in salepoints" :key="i">
                            <td>
                              <v-avatar v-if="item.image"
                                        size="35"
                                        class="zoom-pointer"
                                        @click="[image = [{src: $fileUrl + item.image}],visible = true]">
                                <v-img :src="$fileUrl+item.image"></v-img>
                              </v-avatar>
                              <v-avatar v-else
                                        size="35"
                                        class="zoom-pointer"
                                        @click="[image = [{src: require('@/assets/avatar.png')}],visible = true]">
                                <v-img :src="require('@/assets/avatar.png')"></v-img>
                              </v-avatar>
                            </td>
                            <td>{{ item.code }}</td>
                            <td>
                              <span class="text-no-wrap">{{ item.name }}</span>
                            </td>
                            <td>{{ item.phone }}</td>
                            <td>{{ item.city ? item.city.name : '-' }}</td>
                            <td>{{ item.region ? item.region.name : '-' }}</td>
                            <td>
                              <v-icon color="success" dense v-if="item.opus_account">mdi-check</v-icon>
                              <v-icon color="red" dense v-else>mdi-close</v-icon>
                            </td>
                            <td>
                              <v-icon color="success" dense v-if="item.gifty_account_id">mdi-check</v-icon>
                              <v-icon color="red" dense v-else>mdi-close</v-icon>
                            </td>
                            <td>
                              <v-chip small color="success">{{ item.points | toCurrency }}</v-chip>
                            </td>
                            <td>
                              <v-icon color="blue" @click="showMore(item)">mdi-plus-circle</v-icon>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <NoResult v-else size="180"/>
                    </div>

                  </v-card-text>

                  <v-divider v-if="total > $totalPerPage"/>
                  <v-card-actions v-if="total > $totalPerPage">
                    <div class="w-100">
                      <v-select dense filled
                                hide-details
                                :items="$perPage"
                                v-model="perPage"></v-select>
                    </div>
                    <v-spacer/>
                    <v-pagination total-visible="10"
                                  circle
                                  v-model="pagination.current"
                                  :length="pagination.total"
                                  @input="getSalepointsByIds"></v-pagination>
                  </v-card-actions>
                </v-card>
              </v-tab-item>

            </v-tabs-items>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--Dialogs -->

    <SalepointDialog ref="salepointDialog"/>

    <vue-easy-lightbox escDisabled
                       moveDisabled
                       :visible="visible"
                       :imgs="image"
                       :index="index"
                       @hide="visible = false"></vue-easy-lightbox>

    <v-dialog v-model="infoDialog"
              width="450"
              persistent>
      <v-card>
        <v-card-text class="pa-4 text-center">

          <v-icon color="primary" size="100" v-if="ids.length">mdi-check-circle-outline</v-icon>
          <v-icon color="primary" size="100" v-else>mdi-close-circle-outline</v-icon>

          <h2 class="my-5 text-uppercase">{{ $t('message.searchRes') }}</h2>

          <p class="fs-16">
            {{ $t('message.salepointRes') }}
            <strong class="primary--text text-decoration-underline">
              {{ ids.length }}
            </strong>
          </p>

          <div class="mt-8 mb-2">

            <v-btn text
                   color="primary"
                   @click="infoDialog=false">
              <v-icon left>{{ $lang === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
              {{ $t('btn.back') }}
            </v-btn>

            <v-btn color="primary"
                   v-if="ids.length"
                   depressed
                   @click="save"
                   :class="$lang==='ar'? 'mr-2' :'ml-2'">
              <v-icon left>mdi-check-circle</v-icon>
              {{ $t('btn.validate') }}
            </v-btn>

          </div>

        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['salepointIds'],
  data: () => ({
    tab: null,
    overlay: false,
    dialog: false,


    infoDialog: false,
    ids: [],
    filter: {
      databaseIds: []
    },

    isLoading: false,
    salepoints: [],
    total: 0,
    perPage: 10,
    pagination: {
      current: 1,
      total: 0
    },

    /**Lightbox**/
    image: '',
    visible: false,
    index: 0,
  }),
  methods: {
    open() {
      this.tab = 'tab-1'
      this.dialog = true
    },

    close() {
      this.dialog = false
    },

    handleFilter() {
      this.$Progress.start()
      this.overlay = true
      let url = "databases/filter/ids"
      HTTP.get(url, {
        params: {
          filter: this.filter
        }
      }).then((res) => {
        this.overlay = false
        this.infoDialog = true
        this.ids = res.data.data
        this.$Progress.finish()
      }).catch((err) => {
        this.overlay = false
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    },

    save() {
      this.$emit('set-salepoint-ids', this.ids)
      this.infoDialog = false
      this.close()
    },

    getSalepointsByIds() {
      this.$Progress.start()
      this.isLoading = true

      HTTP.get('/databases/salepoints-by-ids?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          ids: this.salepointIds ? this.salepointIds : this.ids,
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.salepoints = res.data.data.data
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.isLoading = false
        console.log(err)
      })
    },

    showMore(salepoint) {
      this.$refs.salepointDialog.open(salepoint)
    }
  },
  watch: {
    perPage() {
      this.getSalepointsByIds()
    }
  },
}
</script>
