<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>
      <ConfirmDialog ref="confirmDialog"/>

      <v-row justify="center">

        <v-col cols="12" lg="9">

          <FormValidation ref="formValidation"
                          :data.sync="campaign"
                          @loading="[isLoading=$event,btnLoading=$event]"
                          module="campaigns"
                          path="investigation"/>

          <CreateProgress :title="$t('campaign.title.key2')"
                          :progress="33"/>

          <draggable :options="{animation:300, handle:'.handle'}"
                     v-model="campaign.investigations"
                     :force-fallback="true"
                     :scroll-sensitivity="200"
                     @start="drag=true"
                     @end="drag=false">

            <v-card class="rounded-lg shadow mb-5"
                    v-for="(investigation,i) in campaign.investigations"
                    :key="i"
                    flat
                    :disabled="isLoading"
                    :loading="isLoading">

              <v-card-title>

                <v-switch hide-details class="ma-0 pa-0"
                          :label="$t('field.isActive')"
                          v-model="investigation.isActive"/>
                <v-spacer/>

                <v-checkbox hide-details class="ma-0 pa-0"
                            :label="$t('field.isModel')"
                            v-model="investigation.isModel"/>

              </v-card-title>

              <v-divider/>

              <v-card-text>
                <v-row>
                  <v-col v-show="$func.langNC('fr')">
                    <v-text-field :label="$t('field.name')"
                                  :hint="$t('hint.fr')"
                                  persistent-hint
                                  class="required"
                                  v-model="investigation.name.fr">
                    </v-text-field>
                  </v-col>
                  <v-col v-show="$func.langNC('en')">
                    <v-text-field :label="$t('field.name')"
                                  :hint="$t('hint.en')"
                                  persistent-hint
                                  class="required"
                                  v-model="investigation.name.en"/>
                  </v-col>
                  <v-col v-show="$func.langNC('ar')">
                    <v-text-field :label="$t('field.name')"
                                  :hint="$t('hint.ar')"
                                  persistent-hint
                                  class="required"
                                  v-model="investigation.name.ar"/>
                  </v-col>
                </v-row>


                <UploadFile type="image"
                            @loading="isLoading=$event"
                            @file="investigation.image = $event"
                            :path.sync="investigation.image"/>


                <v-row>
                  <v-col>
                    <DateRange :dates.sync="investigation.dates"
                               @dates="investigation.dates = $event"/>
                  </v-col>
                  <v-col>
                    <v-text-field :label="$t('field.amount')"
                                  class="required"
                                  v-model="investigation.amount"/>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col v-show="$func.langNC('fr')">
                    <v-textarea rows="1"
                                :label="$t('field.description')"
                                :hint="$t('hint.fr')"
                                persistent-hint
                                v-model="investigation.description.fr"/>
                  </v-col>
                  <v-col v-show="$func.langNC('en')">
                    <v-textarea rows="1"
                                :label="$t('field.description')"
                                :hint="$t('hint.en')"
                                persistent-hint
                                v-model="investigation.description.en"/>
                  </v-col>
                  <v-col v-show="$func.langNC('ar')">
                    <v-textarea rows="1"
                                :label="$t('field.description')"
                                :hint="$t('hint.ar')"
                                persistent-hint
                                v-model="investigation.description.ar"/>
                  </v-col>
                </v-row>

                <v-row align="center">

                  <v-col cols="6">
                    <DatabasesDialog :salepointIds="investigation.salepointIds"
                                     @set-salepoint-ids="setSalepointIds(i,$event)"/>
                  </v-col>

                  <v-col cols="6" class="text-right">
                    <v-chip :color="investigation.salepointIds.length ? 'success' :'error'">
                      {{ $t('message.salepointAss') }}
                      {{ investigation.salepointIds.length }}
                    </v-chip>
                  </v-col>

                </v-row>

              </v-card-text>
              <v-divider/>
              <v-card-actions>
                <v-btn color="blue" depressed
                       class="transform-none"
                       fab dark small>
                  {{ i + 1 }}
                </v-btn>

                <v-spacer/>

                <v-tooltip bottom color="primary"
                           v-if="campaign.investigations.length > 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                           @click="deleteInvestigation(i)"
                           v-bind="attrs"
                           v-on="on">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>

                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                           @click="duplicateInvestigation(investigation)"
                           v-bind="attrs"
                           v-on="on">
                      <v-icon>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.duplicate') }}</span>
                </v-tooltip>

                <v-tooltip top color="primary" v-if="campaign.investigations.length > 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="handle" icon
                           v-on="on">
                      <v-icon>mdi-drag-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.drag') }}</span>
                </v-tooltip>


              </v-card-actions>
            </v-card>

          </draggable>

          <div class="text-center">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab depressed dark
                       color="blue"
                       small
                       @click="addInvestigation"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.add') }}</span>
            </v-tooltip>
          </div>

          <v-row class="mt-1">
            <v-col>
              <v-btn text
                     color="primary"
                     depressed
                     to="/campaigns/create/infoPlanning">
                <v-icon left>{{ $lang === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                {{ $t('btn.back') }}
              </v-btn>
            </v-col>
            <v-spacer/>
            <v-col :class="'text-'+$float">
              <v-btn color="primary"
                     :disabled="isLoading"
                     :loading="btnLoading"
                     @click="next"
                     type="submit"
                     depressed>
                {{ $t('btn.next') }}
                <v-icon right v-html="'mdi-arrow-'+$float"></v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>


    </v-container>
  </div>
</template>

<script>
import DatabasesDialog from "@/views/campaigns/create/components/DatabasesDialog";

export default {
  components: {DatabasesDialog},
  data() {
    return {
      isLoading: false,
      btnLoading: false,
    }
  },
  methods: {

    async next() {
      if (await this.$refs.formValidation.handleValidate()) {
        await this.$store.commit('campaign/toggelStepp2', 1)
        await this.$router.push('/campaigns/create/tasks')
      }
    },

    addInvestigation() {
      this.$store.commit('campaign/addInvestigation')
      this.$vuetify.goTo(document.body.scrollHeight - 200)
    },

    async deleteInvestigation(i) {
      if (await this.$refs.confirmDialog.open()) {
        await this.$store.commit('campaign/deleteInvestigation', i)
      }
    },

    duplicateInvestigation(data) {
      this.$store.commit('campaign/duplicateInvestigation', data)
      this.$vuetify.goTo(document.body.scrollHeight - 200)
    },

    setSalepointIds(investigationIndex, salepointIds) {
      let data = {
        investigationIndex,
        salepointIds
      }
      this.$store.commit('campaign/setInvestigationSalepointIds', data)
    }
  },
  computed: {
    campaign() {
      return this.$store.state.campaign.campaign
    },
    breadcrumbs() {
      return [
        {to: '/campaigns', text: 'breadcrumb.campaigns'},
        {text: 'breadcrumb.create', disabled: true},
        {text: 'breadcrumb.infoPlanning', disabled: true},
        {text: 'breadcrumb.investigations', disabled: false},
        {text: 'breadcrumb.tasks', disabled: true},
      ]
    },
  }
}
</script>

<style scoped>

</style>